import rolodex from 'goodeggs-rolodex';
import {Helmet} from 'react-helmet-async';
import {connect} from 'react-redux';
import React, {Component} from 'react';

import PrintableGiftCard from '../components/printable_gift_card';

class PrintPage extends Component {
  static reducer(state) {
    return state;
  }

  componentDidMount() {
    window.print();
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Print Gift Card | Good Eggs</title>
        </Helmet>

        <PrintableGiftCard
          giftCard={this.props.giftCard}
          eaterHelp={{
            phone: rolodex.eaterHelpPhoneNumber(),
          }}
        />
      </>
    );
  }
}

PrintPage.pageName = 'Print Gift Card';

export default connect((state) => state)(PrintPage);
